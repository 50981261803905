// Core React
import Layout from "@components/layout/Layout/Layout"
import Homepage from "@components/partials/HomePage"
import React from "react"

// Render
const WhelpsHomePage = () => (
  <Layout
    title="WhelpS NFT - 7,777 Dragons Evolving on the Blockchain."
    contentClassName="homePageContent px-0 max-w-full"
    page="home"
  >
    <Homepage />
  </Layout>
)
export default WhelpsHomePage
