import React from "react"
import parse from "html-react-parser"
import {
  teamWhelpsData,
  whelpsAbilityData,
  gameModesData,
  teamData,
} from "@util/whelpsData"

import "./homepage.scss"

export default function Homepage() {
  const VideoBox = () => {
    return (
      <div className="relative video-section">
        <video autoPlay muted loop className="h-full w-full">
          <source src="/home/bg_video.mp4" type="video/mp4" />
        </video>
        <div className="bg-overflow absolute left-0 top-0 w-full h-full">
          <div className="absolute top-2 sm:top-8 lg:top-12 left-2 sm:left-8 lg:left-12">
            <div className="flex flex-col justify-center items-center gap-2 sm:gap-4 xl:gap-8 mt-3 sm:mt-6 px-4 sm:px-6 lg:px-8">
              <a className="w-10 sm:w-16 md:w-20 lg:w-24 xl:w-36">
                <img src="/home/icon_f2p.png" alt="f2p icon" className="w-full" />
              </a>
              <a className="w-10 sm:w-16 md:w-20 lg:w-24 xl:w-36">
                <img src="/home/icon_peo.png" alt="peo icon" className="w-full" />
              </a>
            </div>
          </div>
          <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 translate-y-1/2 sm:-translate-y-1/2 flex flex-col items-center justify-center w-full">
            <a href="/demo" className="bg-blue border-2 border-blue-100 font-poppins-bold keychainify-checked py-1 sm:py-3 px-6 sm:px-8 rounded-md text-lg sm:text-xl md:text-2xl xl:text-3xl text-center text-white uppercase">
              Download Demo
            </a>
          </div>
        </div>
      </div>
    )
  }

  const DemoBox = () => {
    return (
      <>
        <img src="/home/bg_border.png" alt="border img" className="w-full h-2 md:h-3 xl:h-4" />
        <div className="relative demo-section bg-image">
          <div className="grid grid-cols-12 gap-6 xl:gap-12 container mx-auto">
            <div className="col-span-12 sm:col-span-8 text-white pb-0 py-16 sm:py-24 md:py-28 xl:py-32 md:text-xl xl:text-2xl lg:leading-8 xl:leading-10">
              <h1 className="text-blue-50 text-3xl md:text-4xl xl:text-5xl font-poppins-bold">
                Welcome to WhelpS: Slipstream
              </h1>
              <p className="mt-6">
                Welcome to the world of WhelpS, a collection which captured the hearts of the NFT community back in 2021. You can view the original collection here
              </p>
              <p className="mt-4">
                WhelpS: Slipstream is an upcoming Play, Earn & Own battle racing video game powered by blockchain
                technology and developed by MasterBrews & Argentics Studios.
              </p>
              <p className="mt-4">
                Heavily inspired by all-time classics like Mario kart and
                Spyro the Dragon, Slipstream has something to offer gamers of all skill levels and wallet sizes, including a free2play mode.
              </p>
              <h3 className="font-poppins-semibold mt-8 sm:mt-12">
                WhelpS:Slipstream Playable Alpha Demo is available now :
              </h3>
              <div className="mt-4 sm:mt-6">
                <a href="/demo" className="bg-blue border-2 border-blue-100 font-poppins-bold keychainify-checked py-1 px-6 rounded-md text-xl md:text-2xl xl:text-3xl text-center text-white uppercase w-full">
                  Download Demo
                </a>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-4">
              <div className="flex flex-col gap-6 pt-12 sm:pt-20 py-20 md:py-24 xl:py-28">
                <img src="/home/demo_item1.png" alt="demo img" />
                <img src="/home/demo_item2.png" alt="demo img" />
              </div>
            </div>
          </div>
        </div>
        <img src="/home/bg_border.png" alt="border img" className="w-full h-2 md:h-3 xl:h-4" />
      </>
    )
  }

  const WhelpsTeamBox = () => {
    return (
      <div className="whelpsTeam-section bg-image py-16 pb-48 sm:pb-16 md:py-20 xl:py-24 relative font-poppins-semibold" id="whelpsTeam">
        <div className="container mx-auto">
          <div className="text-center text-blue-50 text-3xl md:text-4xl xl:text-5xl font-poppins-bold">
            Meet The Whelps:
          </div>
          <div className="flex flex-wrap justify-center gap-y-24 gap-x-4 mt-32">
            {
              teamWhelpsData.map((dt, idx) => (
                <WhelpsTeamItem {...dt} key={idx}></WhelpsTeamItem>
              ))
            }
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-start sm:items-center pl-12 sm:pl-0 gap-4 md:gap-6 xl:gap-12 absolute left-1/2 bottom-5 transform -translate-x-1/2 w-full">
          {whelpsAbilityData.map((item, idx) => (
            <div className="flex justify-center items-center text-white" key={idx}>
              <div className="h-5 w-5" style={{ background: item.color }}></div>
              <div className="ml-2">{item.name}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const WhelpsTeamItem = (props) => {
    return (
      <div className="whelpsTeam-panel relative py-4 px-6 border-2 border-blue-50 rounded-2xl">
        <img src={props.image} alt="whelpsTeam Image" className={`absolute left-1/2  ${props.top} transform -translate-x-1/2 ${props.translateY} ${props.width}`} />
        <h3 className="text-center mt-10 mb-6 text-white text-2xl">
          {props.name}
        </h3>
        <img src={props.status} alt="whelpsTeam status" className="w-full" />
      </div>
    )
  }

  const GameModesBox = () => {
    return (
      <div className="GameModule-section bg-blue">
        <div className="h-2 md:h-3 xl:h-4 bg-white w-full"></div>
        <div className="container mx-auto pt-16 md:pt-20 xl:pt-24">
          <div className="text-center text-white text-3xl md:text-4xl xl:text-5xl font-poppins-bold">
            Game Modes:
          </div>
          <div className="grid grid-cols-12 gap-6 xl:gap-8 2xl:gap-12 mt-12 2xl:px-24 pb-12 md:pb-16 xl:pb-20">
            {
              gameModesData.map((dt, idx) => (
                <GameModesItem {...dt} key={idx}></GameModesItem>
              ))
            }
          </div>
        </div>
      </div>
    )
  }

  const GameModesItem = (props) => {
    return (
      <div className="col-span-12 md:col-span-6 bg-white text-black border-4 border-blue-100 text-center px-4 md:px-6 xl:px-12 py-6 xl:pb-12 flex flex-col items-center">
        <img src={props.icon} alt="game icon" className="h-20 md:h-24 xl:h-28" />
        <h2 className="text-2xl md:text-3xl xl:text-4xl font-poppins-bold mt-2 md:mt-4 mb-4 md:mb-6">
          {props.title}
        </h2>
        <div className="text-left font-poppins text-lg leading-6">
          {parse(props.content)}
        </div>
      </div>
    )
  }

  const TeamBox = () => {
    return (
      <div className="team-section bg-white pb-32" id="team">
        <img src="/home/blue_border.png" alt="border image" className="w-full xl:h-40" />
        <div className="container mx-auto mt-8">
          <div className="text-center text-3xl md:text-4xl xl:text-5xl font-poppins-bold text-blue">
            Meet The Team
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 md:gap-4 lg:gap-6 2xl:gap-10 mt-4 md:mt-12 ">
            {
              teamData.map((dt, idx) => (
                <TeamItem {...dt} key={idx}></TeamItem>
              ))
            }
          </div>
        </div>
      </div>
    )
  }

  const TeamItem = (props) => {
    return (
      <div className="border-4 border-blue-100 bg-blue flex flex-col sm:flex-row items-center sm:items-start mt-6 md:mt-0 px-4 py-4">
        <img src={props.image} alt="Team Image" className="w-2/5 sm:w-1/4" />
        <div className="text-white ml-0 sm:ml-4 mt-4 sm:mt-0 flex flex-col items-center sm:items-start sm:w-3/4">
          <h2 className="uppercase font-poppins-bold text-2xl md:text-3xl text-center sm:text-left">
            {props.name}
          </h2>
          <p className="text-center sm:text-left font-poppins md:text-lg c-leading-6">
            {props.content}
          </p>
          <div className="flex items-center justify-start gap-3 mt-3">
            {props.twitter != '' && (
              <a
                href={props.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/icon/icon_twitter.png" alt="twitter icon" className="h-10" />
              </a>
            )}
            {props.linkedin != '' && (
              <a
                href={props.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/icon/icon_linkedin.png" alt="linkedin icon" className="h-10" />
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="font-poppins">
      <VideoBox />
      <DemoBox />
      <WhelpsTeamBox />
      <GameModesBox />
      <TeamBox />
    </div>
  )
}
